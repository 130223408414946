.backgroundImage {
	background-color: var(--color-grey-900);
	background-image: url("../../../../img/svg/background-waves.svg");
	background-repeat: no-repeat;
	background-repeat: no-repeat;
	background-size: cover;
	background-position-y: top;
}

// -------------------------------------------------
// Containers
// -------------------------------------------------

.loginPageContainer {
	min-height: 100vh;
	width: 100vw;
	padding: 0 5vw;

	@media screen and (min-width: 1360px) {
		padding-top: 4rem;
	}
}

.inputGroup {
	max-width: 320px;
}

.pageGrid {
	align-items: center;
	display: grid;
	grid-template-rows: auto;
	grid-template-columns: 1fr;
	grid-template-areas: "card art";

	@media screen and (min-width: 768px) {
		grid-template-columns: 1fr 1fr;
		grid-template-areas: "cart art";
	}

	@media screen and (max-width: 570px) {
		grid-template-columns: 1fr;
		grid-template-areas:
			"art"
			"cart";
	}
}

// -------------------------------------------------
// Card
// -------------------------------------------------

.card {
	grid-area: cart;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	& > div {
		@media screen and (max-width: 570px) {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
	}

	h1 {
		color: var(--color-secondary-400);
		font-family: var(--font-family);
		font-weight: 500;
		font-size: 25px;
		margin: 0 0 0.3rem;

		strong {
			display: flex;
			font-size: 42px;
			margin-bottom: 19px;
		}
	}
	& > div > span {
		color: var(--color-grey-200);
		font-family: var(--font-family);
		font-size: 16px;
		font-weight: 400;
		margin: 7px 0 17px 0;

		@media screen and (max-width: 570px) {
			text-align: center;
		}
	}
}

.buttonsCard {
	@media screen and (max-width: 570px) {
		display: flex;
	}
	& > div:first-child {
		button {
			background: transparent !important;
			color: var(--color-secondary-400);
			border: 2px solid var(--color-secondary-400);
			margin-right: 25px;
			font-family: var(--font-family);
			font-weight: bold;

			& > span {
				svg {
					color: var(--color-secondary-400);
				}
			}

			&:hover {
				background: var(--color-secondary-400) !important;
				color: var(--color-white);
				-webkit-transition: background-color 50ms linear;
				-ms-transition: background-color 50ms linear;
				transition: background-color 50ms linear;

				& > span {
					svg {
						color: var(--color-white);
					}
				}
			}
		}
	}
	& > div:last-child {
		button {
			font-family: var(--font-family);
			background: var(--color-secondary-400) !important;
			font-weight: bold;

			&:hover {
				background: var(--color-secondary-500) !important;
				-webkit-transition: background-color 50ms linear;
				-ms-transition: background-color 50ms linear;
				transition: background-color 50ms linear;
			}
		}
	}
}

// Image Empty

.imageArt {
	grid-area: art;
	svg {
		width: 100%;
		padding: 1rem;
		height: 70vh;

		@media screen and (max-width: 570px) {
			height: auto;
		}
	}
}
