.header {
	position: fixed;
	top: 0;
	/* Fixed */
	max-height: 70px;
	width: 100%;
	z-index: 9;
	display: grid;
	grid-template-columns: 1fr minmax(auto, 21%);
	grid-template-areas: "logo search nav user";
	column-gap: 3%;



	background-color: var(--color-black);
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0);
	padding: 0 5rem;

	@media screen and (max-width: 1070px) {
		grid-template-columns: 1fr 2fr;
	}

	@media screen and (max-width: 767px) {
		grid-template-areas: "logo burger""nav nav";
		padding: 0 2rem;
	}
}

.logo {
	grid-area: logo;
	align-items: center;
	display: flex;
	gap: 1rem;
	margin: 15px 0;

	div>svg {
		max-height: 30px;
		max-width: 83px;
	}

	&>div:hover {
		cursor: pointer;
	}

	span {
		margin-left: 10px;
		color: var(--color-grey-500);
	}
}

.nav {
	grid-area: nav;
	grid-template-columns: repeat(4, auto);
	column-gap: 2rem;
	align-items: center;
	justify-items: center;
	display: grid;
	visibility: visible;
	opacity: 1;
	transition: visibility 0.3s linear, opacity 0.3s linear;

	@media screen and (max-width: 767px) {
		grid-template-columns: none;
		grid-row-gap: 20px;

		padding: 30px 0 30px;
		margin-top: 2px;
		background: var(--color-grey-700);
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
	}

	a {
		color: var(--color-white);
		font-size: 20px;
		font-weight: 500;
		transition: 0.5s;
		text-decoration: none;

		&:hover {
			transform: scale(1.1);
		}
	}

	button {
		outline: none;
		border: none;
		color: var(--color-white);
		background-color: rgba(0, 0, 0, 0);
		cursor: pointer;
	}
}

.navMobileHide {
	visibility: hidden;
	opacity: 0;
}

.navMobileNone {
	display: block;

	@media screen and (min-width: 767px) {
		display: none;
	}
}

.burger {
	display: none;
	grid-area: burger;
	padding: 0;
	justify-self: end;
	font-size: 40px;
	border: none;
	background: none;
	outline: none;
	transition: 0.1s;
	color: var(--color-white);

	&:active {
		transform: scale(1.2);
	}

	@media screen and (max-width: 767px) {
		display: inline;
	}

	span {
		display: flex;

	}
}

.user {
	grid-area: user;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	position: relative;
	cursor: pointer;

	color: var(--color-white);
	font-family: var(--font-family);
	font-size: 1.2rem;

	@media screen and (max-width: 767px) {
		display: none;
	}
}

.userIcon img {
	margin-left: 1rem;
	background-color: var(--color-grey-600);
	width: 44px;
	height: 44px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 300px;
}

.userMenu {
	min-width: 220px;
	display: none;
	top: 70px;
	position: absolute;
	right: 10px;
	padding: var(--spacing-padding-300);

	background: var(--color-grey-050);
	color: var(--color-grey-900);
	border: 1px solid var(--color-white);
	border-radius: var(--border-300);
	border-top-right-radius: 0;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

	&::after {
		display: block;
		right: 0;
		content: " ";
		height: 0;
		top: -10px;
		width: 0;
		position: absolute;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-bottom: 10px solid #f9f9f9;
	}

	&::before {
		display: block;
		right: -1px;
		content: " ";
		height: 0;
		top: -11px;
		width: 0;
		position: absolute;
		border-left: 11px solid transparent;
		border-right: 11px solid transparent;
		border-bottom: 11px solid var(--color-white);
	}

	ul {
		list-style-type: none;
		margin: 0;
		display: flex;
		flex-direction: column;
		align-items: flex-end !important;


		li {
			padding: 0.2rem 0.5rem;
			line-height: normal;
			color: var(--color-grey-600);
			cursor: pointer;
			text-align: right !important;

			&:nth-child(1) {
				padding-top: 0.5rem;
				font-weight: 600;
			}

			&:nth-child(3) {
				padding-top: 0.7rem;
			}

			hr {
				width: 150px !important;
				margin: 0.5Srem !important;
				border: none;
				height: 0.5px;
				background-color: var(--color-grey-200);
			}

			span {
				width: 20px;
				margin-right: 10px;
				text-align: center;
				color: var(--color-grey-400);
			}

			&:hover {
				color: var(--color-secondary-400);

				span {
					color: var(--color-secondary-400);
				}

			}
		}

		.exit {
			display: flex;
			flex-direction: row !important;

			svg {
				margin-left: 0.4rem;
				margin-top: 3px;
			}
		}

	}
}