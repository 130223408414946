.containerWithOutBreadcrumbs {
	background-color: var(--color-grey-900);

	height: 100%;
	min-height: 100vh;
	width: 100%;

	padding: 90px 28px 20px;

	@media (min-width: 768px) {
		padding: 110px 70px 40px;
	}
}

.flex {
	display: flex;
}

.inlineBlock {
	display: inline-block;
}

.flex-end {
	display: flex;
	justify-content: end;
}

.flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.spaceBetween {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.fitContent {
	width: fit-content;
}