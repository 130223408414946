// ------------------------------------------------------
// Margin
// ------------------------------------------------------
.mt-0 {
	margin-top: 0px !important;
}
.mr-0 {
	margin-right: 0px !important;
}
.mb-0 {
	margin-bottom: 0px !important;
}
.ml-0 {
	margin-left: 0px !important;
}

// Margin Top
.mt-4 {
	margin-top: 4px !important;
}
.mt-5 {
	margin-top: 5px !important;
}
.mt-8 {
	margin-top: 8px !important;
}
.mt-10 {
	margin-top: 10px !important;
}
.mt-15 {
	margin-top: 15px !important;
}
.mt-16 {
	margin-top: 16px !important;
}
.mt-20 {
	margin-top: 20px !important;
}
.mt-24 {
	margin-top: 24px !important;
}
.mt-48 {
	margin-top: 48px !important;
}

// Margin Right
.mr-4 {
	margin-right: 4px !important;
}
.mr-5 {
	margin-right: 5px !important;
}
.mr-8 {
	margin-right: 8px !important;
}
.mr-10 {
	margin-right: 10px !important;
}
.mr-15 {
	margin-right: 15px !important;
}
.mr-16 {
	margin-right: 16px !important;
}
.mr-20 {
	margin-right: 20px !important;
}
.mr-24 {
	margin-right: 24px !important;
}
.mr-48 {
	margin-right: 48px !important;
}

// Margin Bottom
.mb-4 {
	margin-bottom: 4px !important;
}
.mb-5 {
	margin-bottom: 5px !important;
}
.mb-8 {
	margin-bottom: 8px !important;
}
.mb-10 {
	margin-bottom: 10px !important;
}
.mb-15 {
	margin-bottom: 15px !important;
}
.mb-16 {
	margin-bottom: 16px !important;
}
.mb-20 {
	margin-bottom: 20px !important;
}
.mb-24 {
	margin-bottom: 24px !important;
}
.mb-48 {
	margin-bottom: 48px !important;
}

// Margin Left
.ml-4 {
	margin-left: 4px !important;
}
.ml-5 {
	margin-left: 5px !important;
}
.ml-8 {
	margin-left: 8px !important;
}
.ml-10 {
	margin-left: 10px !important;
}
.ml-15 {
	margin-left: 15px !important;
}
.ml-16 {
	margin-left: 16px !important;
}
.ml-20 {
	margin-left: 20px !important;
}
.ml-24 {
	margin-left: 24px !important;
}
.ml-48 {
	margin-left: 48px !important;
}

// ------------------------------------------------------
// Padding
// ------------------------------------------------------
.pt-0 {
	padding-top: 0px !important;
}
.pr-0 {
	padding-right: 0px !important;
}
.pb-0 {
	padding-bottom: 0px !important;
}
.pl-0 {
	padding-left: 0px !important;
}

// Padding Top
.pt-4 {
	padding-top: 4px !important;
}
.pt-5 {
	padding-top: 5px !important;
}
.pt-8 {
	padding-top: 8px !important;
}
.pt-10 {
	padding-top: 10px !important;
}
.pt-15 {
	padding-top: 15px !important;
}
.pt-16 {
	padding-top: 16px !important;
}
.pt-20 {
	padding-top: 20px !important;
}
.pt-24 {
	padding-top: 24px !important;
}
.pt-48 {
	padding-top: 48px !important;
}

// Padding Right
.pr-4 {
	padding-right: 4px !important;
}
.pr-5 {
	padding-right: 5px !important;
}
.pr-8 {
	padding-right: 8px !important;
}
.pr-10 {
	padding-right: 10px !important;
}
.pr-15 {
	padding-right: 15px !important;
}
.pr-16 {
	padding-right: 16px !important;
}
.pr-20 {
	padding-right: 20px !important;
}
.pr-24 {
	padding-right: 24px !important;
}
.pr-48 {
	padding-right: 48px !important;
}

// Padding Bottom
.pb-4 {
	padding-bottom: 4px !important;
}
.pb-5 {
	padding-bottom: 5px !important;
}
.pb-8 {
	padding-bottom: 8px !important;
}
.pb-10 {
	padding-bottom: 10px !important;
}
.pb-15 {
	padding-bottom: 15px !important;
}
.pb-16 {
	padding-bottom: 16px !important;
}
.pb-20 {
	padding-bottom: 20px !important;
}
.pb-24 {
	padding-bottom: 24px !important;
}
.pb-48 {
	padding-bottom: 48px !important;
}

// Padding Left
.pl-4 {
	padding-left: 4px !important;
}
.pl-5 {
	padding-left: 5px !important;
}
.pl-8 {
	padding-left: 8px !important;
}
.pl-10 {
	padding-left: 10px !important;
}
.pl-15 {
	padding-left: 15px !important;
}
.pl-16 {
	padding-left: 16px !important;
}
.pl-20 {
	padding-left: 20px !important;
}
.pl-24 {
	padding-left: 24px !important;
}
.pl-48 {
	padding-left: 48px !important;
}


// width
.w-100 {
	width: 100%;
}
.w-90 {
	width: 90%;
}
.w-80 {
	width: 80%;
}
.w-50 {
	width: 50%;
}

// Top
.top-100 {
	top: -100px;
}
.top-85 {
	top: -85px;
}
