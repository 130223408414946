// ------------------------------------
// text-align
// ------------------------------------
.text-center{
	text-align: center !important;
}
.text-right{
	text-align: right !important;
}
.text-muted{
	color: var(--color-grey-500) !important;
}
.text-muted-800{
	color: var(--color-grey-800) !important;
}
.text-muted-600{
	color: var(--color-grey-600) !important;
}
.uppercase{
	text-transform: uppercase;
}
.fw-400{
	font-weight: 400!important;
}
.fw-600{
	font-weight: 400!important;
}

.fz-14{
	font-size: 14px;
}
