.container {
	:global {
		.ui.active.dimmer {
			opacity: 0.9;
		}
	}
}

.text {
	color: var(--color-secondary-400);
	font-size: 1.4rem !important;
	font-weight: 400;
	margin-top: 1rem;
}